
<div class="d-flex align-items-center">
  <!-- START User Info-->
  <div class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20">
    <span class="semi-bold">{{userName}}</span>
  </div>
  <div
    dropdown
    class="dropdown pull-right d-lg-block d-none">
    <button
      dropdownToggle
      type="button"
      class="profile-dropdown-toggle"
      id="profileDropdown">
      <span class="thumbnail-wrapper d32 circular inline">
        <img
          pgRetina
          src="/assets/img/human.png"
          src1x="/assets/img/profiles/avatar.jpg"
          src2x="/assets/img/profiles/avatar_small2x.jpg">
      </span>
    </button>
    
    <div
      *dropdownMenu
      role="menu"
      class="dropdown-menu dropdown-menu-right profile-dropdown">
      <a class="dropdown-item">
        <i class="fs-13 pg pg-settings_small"></i>
        <span>Cài đặt</span>
      </a>
      <a class="dropdown-item">
        <i class="fs-13 pg pg-signals"></i>
        <span>Trợ giúp</span>
      </a>
      <a
        class="clearfix bg-master-lighter dropdown-item"
        (click)="logOut()">
        <span class="pull-left">Đăng xuất</span>
        <span class="pull-right">
          <i class="fs-13 pg pg-power"></i>
        </span>
      </a>
    </div>
  </div>
  <!-- END User Info-->
</div>