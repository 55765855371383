<span style="display: block; position: relative;" (click)="_openCalendar()" cdkOverlayOrigin #origin="cdkOverlayOrigin" #trigger>
  <input
    #maindata
    type="input"
    (blur)="onTouched();"
    mask="00/00/0000"
    class="form-control"
    [attr.placeholder]="_placeHolder"
    [value]="_value|pgDate:Format"
    [readonly]="readonly">
  <i class="picker-clear anticon anticon-cross-circle"
    id="clear-icon"
    *ngIf="_showClearIcon"
    (click)="onTouched();_clearValue($event)">
    <svg viewBox="64 64 896 896" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
  </i>
  <span class="picker-icon"></span>

</span>
<ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop [cdkConnectedOverlayPositions]="_positions" [cdkConnectedOverlayOrigin]="origin" (backdropClick)="_closeCalendar();_inputDay(maindata)" (detach)="_closeCalendar()" (positionChange)="onPositionChange($event)"
    [cdkConnectedOverlayOpen]="_open">
    <div class="dropdown open show" [class.top]="_dropDownPosition==='top'" [class.bottom]="_dropDownPosition==='bottom'" [@dropDownAnimation]="_dropDownPosition">
        <div class="pg-calendar dropdown-menu show bg-white" [class.mt-4]="!readonly" tabindex="0" [class.time]="ShowTime">
            <div class="date-panel">
                <div class="calendar-header">
                    <div class="d-flex flex-row " style="position: relative;" *ngIf="_mode!='time'">
                        <!-- <a class="prev-month-btn" title="" (click)="_preMonth()">
                <i class="pg  pg-arrow_left"></i>
              </a> -->
                        <span class="d-flex flex-row full-width justify-content-center">
                <a  href="javascript:void(0);"  class="year-select" title="" (click)="_changeDecadeView($event)">{{_showYear }}</a>
              </span>
                        <!-- <a class="next-year-btn" title="" (click)="_nextYear()">
                <i class="pg  pg-arrow_right"></i>
              </a> -->
                    </div>
                    <pg-datepicker-scroller [selectedMonth]="_showMonth" *ngIf="_mode!='time'"></pg-datepicker-scroller>

                    <div style="position: relative;" *ngIf="_mode=='time'">
                        <span class="my-select">
              <a href="javascript:void(0);"  class="year-select" title="Choose a month">{{_selectedYear}}</a>
              <a href="javascript:void(0);" class="month-select" title="Choose a month">{{ _showMonth + 1 }}</a>
              <a href="javascript:void(0);"  class="day-select">{{ _selectedDate }}</a>
            </span>
                    </div>
                    <!-- smonth : {{_selectedMonth}}
            showmonth {{_showMonth}}
            showyear {{_showYear}}
            selected year {{_selectedYear}} -->
                    <div class="card overlay-view" *ngIf="_mode=='month'">
                        <!-- <span class="month-panel-year-select-arrow">x</span> -->
                        <div class="card-header month-header">
                            <div class="d-flex flex-row justify-content-around" style="position: relative;">
                                <a href="javascript:void(0);" class="month-panel-prev-year-btn" title="" (click)="_preYear()">
                                    <i class="pg  pg-arrow_left"></i>
                                </a>
                                <a href="javascript:void(0);" class="month-panel-year-select year-select" title="" (click)="_changeDecadeView($event)">
                                    <span class="">{{ _showYear }}</span>
                                </a>
                                <a href="javascript:void(0);" class="month-panel-next-year-btn" title="" (click)="_nextYear()">
                                    <i class="pg  pg-arrow_right"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body relative">
                            <pg-calendar-view [ClearTime]="!ShowTime" [DisabledDate]="DisabledDate" (ClickDay)="_clickDay($event)" [ShowMonth]="_showMonth" [ShowYear]="_showYear" [Value]="_value" (ClickMonth)="_clickMonth($event)" [Mode]="'year'" [FullScreen]="false" [ShowHeader]="false"
                                pgDatePicker>
                            </pg-calendar-view>
                        </div>
                    </div>
                    <div class="card overlay-view year-panel" *ngIf="_mode=='decade'">
                        <div class="card-header decade">
                            <div class="d-flex flex-row justify-content-around" style="position: relative;">
                                <a class="year-panel-prev-decade-btn" title="" (click)="_preDecade()">
                                    <i class="pg  pg-arrow_left"></i>
                                </a>
                                <a class="year-panel-decade-select" title="">
                                    <span class="year-panel-decade-select-content">{{ _startDecade }}-{{ _startDecade + 9 }}</span>
                                </a>
                                <a class="year-panel-next-decade-btn" title="" (click)="_nextDecade()">
                                    <i class="pg  pg-arrow_right"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body relative">
                            <div class="pg-calendar-view">
                                <div class="d-flex flex-column justify-content-between full-height">
                                    <div class="d-flex justify-content-between flex-1" *ngFor="let tr of _yearPanel">
                                        <ng-template ngFor let-td [ngForOf]="tr">
                                            <div class="cell grid-cell year-panel-last-decade-cell" *ngIf="td=='start'">
                                                <a class="date" (click)="_preDecade()"><span class="value">{{ _startDecade - 1 }}</span></a>
                                            </div>
                                            <div *ngIf="(td!='start')&&(td!='end')" [attr.title]="_startDecade+td" class="cell grid-cell" [ngClass]="{'selected-cell':(_startDecade+td==_showYear)}">
                                                <a class="date" (click)="_setShowYear(_startDecade+td,$event)"><span class="value">{{ _startDecade + td }}</span></a>
                                            </div>
                                            <div class="cell grid-cell year-panel-next-decade-cell" *ngIf="td=='end'">
                                                <a class="date" (click)="_nextDecade()"><span class="value">{{ _startDecade + 10 }}</span></a>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <pg-timepicker-inner [Format]="ShowTime&&ShowTime.Format||'HH:mm:ss'" [Disabled]="ShowTime&&ShowTime.Disabled||false" [DisabledHours]="ShowTime&&ShowTime.DisabledHours||null" [DisabledMinutes]="ShowTime&&ShowTime.DisabledMinutes||null" [DisabledSeconds]="ShowTime&&ShowTime.DisabledSeconds||null"
                    [HideDisabledOptions]="ShowTime&&ShowTime.HideDisabledOptions||false" [ngModel]="_value" (ngModelChange)="_changeTime($event)" *ngIf="ShowTime&&(_mode == 'time')"></pg-timepicker-inner>
                <div class="calendar-body">
                    <pg-calendar-view [ClearTime]="!ShowTime" [DisabledDate]="DisabledDate" (ClickDay)="_clickDay($event)" [ShowMonth]="_showMonth" [ShowYear]="_showYear" [Value]="_value" (ClickMonth)="_clickMonth($event)" [Mode]="'month'" [FullScreen]="false" [ShowHeader]="false"
                        pgDatePicker></pg-calendar-view>
                </div>
                <div class="footer row" *ngIf="!HideFooter">
                    <a class="btn btn-link col" [class.today-btn-disabled]="_disabledToday" [attr.title]="_today|pgDate:Format" (click)="_changeToToday()">{{ (ShowTime ? 'This Moment' : 'Today') }}</a>
                    <a class="btn btn-link col" (click)="_changeTimeView($event)" *ngIf="(_mode != 'time')&&ShowTime">Choose Time</a>
                    <a class="btn btn-link col" (click)="_changeYearView($event)" *ngIf="(_mode == 'time')&&ShowTime">Select Date</a>
                    <a class="btn btn-primary btn-sm col" *ngIf="ShowTime" (click)="_closeCalendar()">Ok</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>