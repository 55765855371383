
  <div class="wrap-scroller" #wrapper>
    <div class="horizontal-dates" style="position: relative;" >
    <div class="d-flex flex-row full-width" #monthSlider  (cdkObserveContent)="_onContentChanges()">
        <ng-container *ngFor="let monthIndex of _List">
            <ng-container *ngIf="(monthIndex === _selectedMonth || monthIndex == _showMonth); else determineBlock">
                <a class="month-select selected-date" title="" #selectedElement  (click)="_changeMonthView()">
                    {{ _getMonthForamated(monthIndex) }}
                </a>
            </ng-container>
            <ng-template #determineBlock>
                <a class="month-select" title="" (click)="_changeMonth(monthIndex)">
                    {{ _getMonthForamated(monthIndex) }}
                </a>
            </ng-template>
        </ng-container>
        </div>             
    </div>
</div>
  