
    <div
      *ngIf="currentMessage"
      class="pgn-wrapper"
      [class.hide]="messages.length == 0"
      [attr.data-position]="currentMessage.options.Position"
      [ngStyle]="style"
      (mouseenter)="isPause = true"
      (mouseleave)="isPause = false">
      <pg-message
        *ngFor="let message of messages; let i = index"
        [Message]="message"
        [Index]="i"
        [IsPause]="isPause"
        [ClickContentSubject]="message.options?.ClickContentSubject">
      </pg-message>
    </div>
  