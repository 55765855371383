
    <div class="form-group form-group-default">
      <label>{{field.Label}}</label>
      <div *ngIf="field.FileConfig.FileType && field.FileConfig.FileType.includes('read')">
        <ng-container *ngTemplateOutlet="list"></ng-container>
        <div *ngIf="field.FileConfig.FileType.includes('write') && field.IsCurrent">
          <ng-container *ngTemplateOutlet="input"></ng-container>
        </div>
      </div>
      <ng-template #input>
        <pg-upload
          [Multiple]="true"
          [Action]="field.FileConfig.UploadUrl"
          [ShowUploadList]="false"
          (Change)="onChangeFile($event)"
          progressType="circle">
          <div class="d-flex flex-column align-items-center">
            <h4 class="semi-bold no-margin">Thả file để tải lên</h4>
            <p>hoặc nhấp vào đây</p>
          </div>
        </pg-upload>
      </ng-template>
      <ng-template #list>
        <div class="d-flex flex-column align-items-center" *ngIf="!field.FileConfig.FileList || field.FileConfig.FileList.length === 0">
          <p>File không sẵn có!</p>
        </div>
        <div
          class="kudo-file"
          *ngFor="let file of field.FileConfig.FileList; let i = index">
          <a
            class="kudo-link"
            href="javascript:void(0)"
            (click)="onFileClick(file)">
            <i class="fa fa-file file-icon"></i>
            <span class="file-info">
              {{file.Name}}
              <span class="date-time">
                {{file.Date | date: 'dd-MM-yyyy'}}
                <span class="divider"></span>
                {{file.Date | date: 'HH:mm:ss'}}
              </span>
            </span>
          </a>
          <i
            class="pg pg-close ml-auto delete-icon"
            *ngIf="field.FileConfig.FileType.includes('write') && field.IsCurrent"
            [tooltip]="'Xóa file'"
            [placement]="'left'"
            (click)="onDeleteFile(file, i)">
          </i>
        </div>
      </ng-template>
    </div>
  