
    <div
      class="form-group form-group-default"
      [formGroup]="group">
      <label>{{field.Label}}</label>
      <textarea
        class="form-control"
        rows="4"
        [formControlName]="field.Name"
        [readonly]="(field.FieldType && !field.FieldType.includes('write')) || !field.IsCurrent">
      </textarea>
    </div>
  