
  <div
    class="form-group form-group-default"
    [formGroup]="group">
    <div
      class="checkbox check-success d-flex align-items-center">
      <input
        id="{{field.Name}}"
        type="checkbox"
        [formControlName]="field.Name">
        <label for="{{field.Name}}">{{field.Label}}</label>
    </div>
  </div>
  