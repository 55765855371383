<ng-container [ngSwitch]="IconType">
  <svg
    *ngSwitchCase="'fi'"
    class="feather"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round">
    <use attr.xlink:href="assets/fonts/feather/feather-sprite.svg#{{IconName}}" />
  </svg>
  <ng-container
    *ngSwitchCase="'letter'">
    {{IconName}}
  </ng-container>
  <i
    *ngSwitchDefault
    class="{{IconType}} {{IconType}}-{{IconName}}">
  </i>
</ng-container>
