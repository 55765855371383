
    <label>{{field.Label}}</label>
    <div
      class="radio radio-primary form-group"
      [formGroup]="group">
      <div
        *ngFor="let item of field.Options">
        <input
          type="radio"
          id="{{item}}"
          [formControlName]="field.Name"
          [value]="item"
          [readonly]="(field.FieldType && !field.FieldType.includes('write')) || !field.IsCurrent">
          <label for="{{item}}">{{item}}</label>
      </div>
    </div>
  