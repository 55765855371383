
    <span
      class="time-picker"
      [ngClass]="{'ant-time-picker-large':Size=='large','ant-time-picker-small':Size=='small'}">
      <div
        class="time-picker-panel"
        [@dropDownAnimation]="'bottom'">
        <div class="time-picker-inner"
          [class.time-picker-column-3]="_showHour&&_showMinute&&_showSecond"
          [class.time-picker-column-2]="_showHour&&_showMinute&&!_showSecond"
          [class.time-picker-column-1]="_showHour&&(!_showMinute)&&(!_showSecond)">
        <div class="time-picker-combobox">
          <div
            class="time-picker-select"
            #hourListInstance
            *ngIf="_showHour">
            <ul>
              <ng-template
                ngFor
                let-_hour
                [ngForOf]="_hourList"
                let-i="index">
                 <li
                   [ngClass]="_hour.name"
                   *ngIf="!(HideDisabledOptions&&_hour.disabled)"
                   [class.option-selected]="_hour.index===_selectedHour"
                   [class.option-disabled]="_hour.disabled"
                   (click)="_selectHour(hourListInstance,_hour.index,_hour.disabled)">
                   {{ _hour.name }}
                 </li>
              </ng-template>
            </ul>
          </div>
          <div
            class="time-picker-select"
            #minuteListInstance
            *ngIf="_showMinute">
            <ul>
              <ng-template
                ngFor
                let-_minute
                [ngForOf]="_minuteList"
                let-i="index">
                 <li
                   [ngClass]="_minute.name"
                   *ngIf="!(HideDisabledOptions&&_minute.disabled)"
                   [class.option-selected]="_minute.index===_selectedMinute"
                   [class.option-disabled]="_minute.disabled"
                   (click)="_selectMinute(minuteListInstance,_minute.index,_minute.disabled)">
                   {{ _minute.name }}
                 </li>
              </ng-template>
            </ul>
          </div>
          <div
            class="time-picker-select"
            #secondListInstance
            *ngIf="_showSecond">
            <ul>
              <ng-template
                ngFor
                let-_second
                [ngForOf]="_secondList"
                let-i="index">
                 <li
                   [ngClass]="_second.name"
                   *ngIf="!(HideDisabledOptions&&_second.disabled)"
                   [class.option-selected]="_second.index===_selectedSecond"
                   [class.option-disabled]="_second.disabled"
                   (click)="_selectSecond(secondListInstance,_second.index,_second.disabled)">
                   {{ _second.name }}
                 </li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </span>