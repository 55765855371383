<div
  class="form-group form-group-default"
  [formGroup]="group"
  *ngIf="(!field.Validations || (field.Validations && field.Validations.length === 0)) &&field.InputType.localeCompare('hidden') !== 0; else TypeHidden">
  <label>{{field.Label}}</label>
  <input
    class="form-control"
    [formControlName]="field.Name"
    [type]="field.InputType"
    [readonly]="(field.FieldType && !field.FieldType.includes('write')) || !field.IsCurrent"
    [textMask]="{mask: inputMask ? (oldMaskVal.includes(inputMask) ? oldMask : mask[inputMask]) : false}">
</div>
<div *ngIf="field.Validations && field.Validations.length > 0">
  <div
    class="form-group form-group-default required"
    [formGroup]="group"
    [class.has-error]="getFormControl(field.Name).invalid
      && (getFormControl(field.Name).dirty
      || getFormControl(field.Name).touched)">
    <label>{{field.Label}}</label>
    <input
      class="form-control"
      [formControlName]="field.Name"
      [type]="field.InputType"
      [readonly]="(field.FieldType && !field.FieldType.includes('write')) || !field.IsCurrent"
      [textMask]="{mask: inputMask ? (oldMaskVal.includes(inputMask) ? oldMask : mask[inputMask]) : false}"
      required>
  </div>
  <div
    *ngIf="getFormControl(field.Name).invalid
      && (getFormControl(field.Name).dirty
      || getFormControl(field.Name).touched)">
    <label class="error">
      This field is required.
    </label>
  </div>
</div>

<ng-template  #TypeHidden>
  <span *ngIf='(!field.Validations || (field.Validations && field.Validations.length === 0))' [formGroup]="group">
    <input
      class="form-control"
      [formControlName]="field.Name"
      [type]="field.InputType"
      [readonly]="(field.FieldType && !field.FieldType.includes('write')) || !field.IsCurrent">
  </span>
</ng-template>